.filter-left[data-v-ec6bd34a] {
  line-height: 32px;
}
.text-right[data-v-ec6bd34a] {
  text-align: right;
}
.filter-item[data-v-ec6bd34a] {
  margin-top: 40px;
  margin-bottom: 30px;
  border-bottom: 2px solid #e4e7ed;
  padding-bottom: 8px;
}
.select-all[data-v-ec6bd34a] {
  width: 100px;
}
.select-remote[data-v-ec6bd34a] {
  width: auto;
}
.radio-group_time[data-v-ec6bd34a] {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 768px) {
.select-remote[data-v-ec6bd34a] {
    width: 140px;
}
.radio-group_time[data-v-ec6bd34a] {
    top: 45px;
}
}
